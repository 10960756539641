import React, {useEffect, useState} from 'react';
import {getFieldsAndDetails} from '../../services/PropertyFieldService';
import {loading} from "../../utils/app_functions";

const PropertyFieldDetails = (props)=>{
    const [fields, setFields] = useState(null);

    let fields_exists = false;

    useEffect(()=>{
        getFields(props.property_id);
    },[])

    const getFields = async (property_id)=>{
        if(property_id){
            loading(true);
            return getFieldsAndDetails(property_id)
                .then(res => {
                    setFields(res?.data.fields)
                    fields_exists = res?.data.fields.length > 0 ? 1 : 0;

                    if(!fields_exists){
                        document.getElementById('field-detail-container')
                            .style.display = 'none';
                    }
                    loading(false);
                }).catch(err => {
                    loading(false);
                })
        }

    }

    return (
        <div className={'d-flex flex-row flex-wrap justify-content-between w-100 overflow-y-auto'}
             style={{fontSize: 12, maxHeight: 500}}>
            {
                fields && fields.map((field)=>{
                    return (
                        <div key={field.id} className={'d-flex flex-row align-items-center w-100-mb m-3'} style={{color: '#565656', width: '45%'}}>
                            <div className={'bold m-1'}>{field.field_label ?? '-'}:</div>
                            <div className={'d-flex flex-row align-items-center m-1'}>
                                <div>{field.value ?? '-'}</div>
                            </div>
                        </div>
                    )
                })
            }
        </div>

    )

}

export default PropertyFieldDetails;