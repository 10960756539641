import React, {useState} from 'react';
import {searchPropertiesAndFilters} from '../../services/PropertyService'
import Item from '../home/items/ItemProduct'
import {loading} from '../../utils/app_functions'
import {Skeleton} from "antd";
import {Box, LinearProgress} from "@mui/material";
import DetectComponentView from '../includes/DetectComponentView';
import { getCookie } from '../../utils/cookies_functions';

export default class SearchResult extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            collection: [],
            requestStatus: false,
            hasCollection: false,
            config: {
                start_id: null,
                search_value: null,
                cities: null,
                mode: null,
                category: null,
                price_from: null,
                price_limit: null,
            },
            isInView: false,
        }
    }

    componentDidMount() {
        if(!this.state.requestStatus){
            if(getCookie('search-config')){
                this.setState({config: JSON.parse(getCookie('search-config'))});

                this.getCollection(true, JSON.parse(getCookie('search-config')));
            }else{
                this.getCollection(true, this.state.config);
            }
        }
    }

    getCollection = async (first_mount_component, config)=>{
        loading(true);
        this.setState({requestStatus: true});

        return searchPropertiesAndFilters(config)
            .then(res => {
                //console.log('res.data.has_more_properties', res.data.has_more_properties)
                this.setState({hasCollection: res.data.has_more_properties});

                if(first_mount_component){
                    this.setState({collection: this.state.collection.concat(res.data.properties)});

                    if(getCookie('search-active')){
                        window.location.href = '#search-result';
                    }
                }else{
                    if(res.data.has_more_properties){
                        this.setState({collection: this.state.collection.concat(res.data.properties)});
                    }
                }

                this.setState({requestStatus: false});
                loading(false);

            }).catch(err => {
                console.log('err', err)
                this.setState({requestStatus: false});
                return loading(false);
            })
    }

    setStatus = (elInView) => {
        this.setState({
            isInView: elInView
        });
    };

    render() {
        console.log('this.state.isInView', this.state.isInView)
        console.log('this.state.hasCollection', this.state.hasCollection)

        if(this.state.isInView && this.state.hasCollection){
            this.setState({
                isInView: false,
                hasCollection: false,
            });

            //const last_product_loaded = this.state.collection.at(-1);
            const last_product_loaded = this.state.collection[this.state.collection.length -1];
            //console.log('last_product_loaded', last_product_loaded)
            //console.log('this.state.collection', this.state.collection)
            if(last_product_loaded !== undefined){
                let new_config = {};
            
                if(getCookie('search-config')){
                    new_config = JSON.parse(getCookie('search-config')); 
                }else{
                    new_config = {
                        start_id: null,
                        search_value: null,
                        cities: null,
                        mode: null,
                        category: null,
                        price_from: null,
                        price_limit: null,
                    }
                }

                new_config.start_id = last_product_loaded.id;

                //console.log('new_config',new_config)

                this.getCollection(false, new_config);

            }

        }

        return (
            <div id={'search-result'} className={'d-flex flex-row flex-wrap w-100 mt-5 align-content-between'} 
                style={{paddingBottom: 400, maxWidth: 1000, margin: 'auto'}}>
                    
                <h3 className={'text-left w-100'}
                    style={{fontSize: 16, color: '#676767', marginLeft: 25}}>
                    Mejores resultados
                </h3>
                {
                    this.state.collection.map((property)=>{
                        return <Item key={property.id} property={property} openInNewTab={true} />
                    })
                }

                <DetectComponentView setElementStatus={(status) => this.setStatus(status)} />

                <Box sx={{ width: '100%' }} style={{display: this.state.requestStatus ? 'block' : 'none'}}>
                    <LinearProgress />
                </Box>

            </div>

        )
    }

}