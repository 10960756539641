import axios from 'axios';

const apiRoute = process.env.REACT_APP_REMOOX_API_ROUTE;

const getFieldsAndDetails = async (property_id)=>{
    return await axios.get(apiRoute + '/properties/property/'+property_id+'/fields/details',
        {
            headers: {
                'apiKey': process.env.REACT_APP_REMOOX_API_KEY,
                'Content-Type': 'application/json',
            }
        }
    );
}

export {
    getFieldsAndDetails,
}