import React from 'react';
import { Space, Spin } from 'antd';

export default class Loading extends React.Component {
    render() {
        return (
            <div id='loading' className="d-none w-100 d-flex flex-row justify-content-center">
                <Space size="middle">
                    <Spin size="medium" />
                </Space>
            </div>
        )
    }
}