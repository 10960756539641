import axios from 'axios';

const apiRoute = process.env.REACT_APP_REMOOX_API_ROUTE;

const getContactsLinks = async (property_id)=>{
    return await axios.get(apiRoute + '/properties/'+property_id+'/contact/links',
        {
            headers: {
                'apiKey': process.env.REACT_APP_REMOOX_API_KEY,
                'Content-Type': 'application/json',
            }
        }
    );
}

export {
    getContactsLinks,
}