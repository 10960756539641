const loading = (state) => {
    state = state === null;
    const logo_selector = document.getElementById('logo');
    const loading_selector = document.getElementById('loading');

    if(state){
        logo_selector.classList.add('d-none');
        loading_selector.classList.remove('d-none');
    }else{
        logo_selector.classList.remove('d-none');
        loading_selector.classList.add('d-none');
    }
}

export {loading}