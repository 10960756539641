import axios from 'axios';

const apiRoute = process.env.REACT_APP_REMOOX_API_ROUTE;

const getPropertiesCollection = async (start_id, order_by, limit)=>{
    return await axios.get(apiRoute + '/properties/last/collection/'+start_id+'/'+order_by+'/'+limit,
        {
            headers: {
                'apiKey': process.env.REACT_APP_REMOOX_API_KEY,
                'Content-Type': 'application/json',
            }
        }
    );
}

const getPropertyById = async (property_id)=>{
    return await axios.get(apiRoute + '/properties/property/'+property_id,
        {
            headers: {
                'apiKey': process.env.REACT_APP_REMOOX_API_KEY,
                'Content-Type': 'application/json',
            }
        }
    );
}

const getRelatedCollectionsByCategory = async (property_id, data)=>{
    return axios.get(apiRoute + '/properties/' + property_id + '/related/collection/category',
        data,
        {
            headers: {
                'apiKey': process.env.REACT_APP_REMOOX_API_KEY,
                'Content-Type': 'application/json',
            }
        }
    );
}

const getRelatedCollections = async (property_id, data)=>{
    return axios.get(apiRoute + '/properties/' + property_id + '/related/collection?start_id=' + data.start_id,
        {
            headers: {
                'apiKey': process.env.REACT_APP_REMOOX_API_KEY,
                'Content-Type': 'application/json',
            }
        }
    );
}

const getCategories = async ()=>{
    return axios.get(apiRoute + '/properties/categories',
        {
            headers: {
                'apiKey': process.env.REACT_APP_REMOOX_API_KEY,
                'Content-Type': 'application/json',
            }
        }
    );
}

const searchPropertiesAndFilters = async (data)=>{
    let url = '/properties/search/filters';

    if(data.search_value){
        url = url + '?search_value=' + data.search_value;
    }else{
        url = url + '?search_value=';
    }

    if(data.cities){
        url = url + '&cities=' + data.cities;
    }

    if(data.mode){
        url = url + '&mode=' + data.mode;
    }

    if(data.category){
        url = url + '&category=' + data.category;
    }

    if(data.price_from){
        url = url + '&price_from=' + data.price_from;
    }

    if(data.price_limit){
        url = url + '&price_limit=' + data.price_limit;
    }

    if(data.start_id){
        url = url + '&start_id=' + data.start_id;
    }

    return axios.get(apiRoute + url,
        {
            headers: {
                'apiKey': process.env.REACT_APP_REMOOX_API_KEY,
                'Content-Type': 'application/json',
            }
        }
    );
}

export {
    getPropertiesCollection, getPropertyById, getRelatedCollections, getRelatedCollectionsByCategory, getCategories, searchPropertiesAndFilters,
}