import React, {useEffect, useState} from 'react';
import ReactHtmlParser from 'react-html-parser';
import {getPropertyById} from '../../services/PropertyService';
import {getRouteViewsCount, setRouteInfo} from '../../services/RouteUserViewService';
import {loading} from '../../utils/app_functions';
import Nav from "../../components/Nav";
import {useParams} from "react-router-dom";
import LinksNavigationHistory from '../../components/includes/LinksNavigationHistory';
import TitleView from '../../components/includes/TitleView';
import {Carousel, Divider, Skeleton, Space, Spin} from 'antd';
import Footer from '../../components/home/footer/Footer'
import {Box, LinearProgress} from "@mui/material";
import PropertyFieldDetails from '../../components/property/PropertyFieldDetails';
import ContactLinks from '../../components/property/ContactLinks';
import RelatedAd from '../../components/property/RelatedAd';
import RelatedAdWithoutCategory from '../../components/property/RelatedAdWithoutCategory';
import ImageAgentWomen from '../../img/product_details/agent_women.svg'
import ImageAgentMan from '../../img/product_details/agent_man.svg'
import {getCookie, setCookie} from "../../utils/cookies_functions";
import VisibilityIcon from '@mui/icons-material/Visibility';

const PropertyDetails = (props)=>{
    const [propertyData, setPropertyData] = useState({
        id: null,
        address: '-',
        price: 0,
        city: {
            name_es: '-',
            name_en: '-',
        },
        country: {
            name_es: '-',
            name_en: '-',
        },
        media: [],
    });
    const property = useParams();
    const [propertyId, setPropertyId] = useState(property.id);
    const [requestStatus, setRequestStatus] = useState(true);
    const [showFeatures, setShowFeatures] = useState(false);
    const [viewsCount, setViewsCount] = useState(0);

    useEffect(()=>{
        //console.log('property.id', property.id)
        newRouteRecord();
        getRouteViews(property.id);
        getProperty(property.id);
        // localStorage.setItem('project_id', property.id);

        window.scrollTo(0, 0)
    },[property])

    const configLinksNavigation = [
        {
            label: 'Inicio',
            url: '/',
        },
        {
            label: 'Detalles del anuncio',
            url: null,
        },
    ];

    const newRouteRecord = async ()=>{
        const pathname = document.location.pathname;

        if(!getCookie(pathname)){
            setCookie(pathname, 1, 0.1);

            return setRouteInfo(pathname, property.id)
                .then(res => {
                    // console.log('res', res)
                }).catch(err => {
                    // console.log('err', err)
                })
        }
    }

    const getRouteViews = async (property_id)=>{
        return getRouteViewsCount(property_id)
            .then(res => {
                setViewsCount(res.data.views_count.toLocaleString());
            }).catch(err => {
                // console.log('err', err)
            })
    }

    const getProperty = async (property_id)=>{
        if(property_id){
            loading(true);
            setRequestStatus(true);
            return getPropertyById(property_id)
                .then(res => {
                    setPropertyData(res.data.property)
                    document.title = res.data.property.title;
                    document.querySelector('meta[name="description"]').setAttribute("content", res.data.property.description);
                    loading(false);
                    setRequestStatus(false);
                    checkFeatureExists();
                }).catch(err => {
                    loading(false);
                    setRequestStatus(false);
                })
        }
    }

    const onChangeCarrucel = (currentSlide)=>{
        // console.log(currentSlide);
    }

    const checkFeatureExists = ()=>{
        const features_exists = localStorage.getItem('property_features_length') === '1';

        if(!features_exists){
            setShowFeatures(0);
        }else{
            setShowFeatures(1);
        }
    }

    return (
        <div id={'container'} className={'w-100'}>
            <Nav />
            <LinksNavigationHistory config={configLinksNavigation} />
            <div className={'w-100 d-flex flex-column'} style={{padding: '0 0 50px 0', margin: '65px 0 0 0'}}>

                <div className={'mt-3'} style={{backgroundColor: 'rgb(161,206,217)'}}>
                    <div className={'w-100 position-relative d-flex flex-column'} style={{maxWidth: 1000, maxHeight: 700,
                        margin: 'auto', backgroundColor: 'rgb(140,178,187)'}}>

                        <div className={'position-absolute d-flex flex-row align-items-center p-2 pr-3 pl-3'}
                             style={{right: 0, bottom: 15, backgroundColor: 'rgba(255,255,255,0.56)', zIndex: 3003, borderRadius: '25px 0 0 25px'}}>
                            <VisibilityIcon style={{fontSize: 20, color: '#8a8a8a'}} />
                            <div style={{fontSize: 12,color: '#707070'}} className={'ml-2'}>{viewsCount}</div>
                        </div>

                        <Carousel afterChange={onChangeCarrucel} autoplay={false}>
                            {
                                propertyData.media?.map((image)=>{
                                    return (
                                        <div>
                                            <a href={process.env.REACT_APP_REMOOX_API_ROUTE + '/properties/'+ property.id 
                                                +'/media/image/builder/' + image?.name} target='_blank' 
                                                    className='d-flex flex-row justify-content-center align-items-center' style={{minHeight: 504}}>
                                                <img key={image.id} src={process.env.REACT_APP_REMOOX_API_ROUTE + '/properties/'+ property.id 
                                                    +'/media/image/builder/' + image?.name} alt="-" />
                                            </a>
                                        </div>
                                        
                                    )
                                })
                            }
                        </Carousel>
                        <div style={{
                            display: requestStatus ? 'block' : 'none', position: 'absolute', top: '2%', left: '2%',
                            width: '100%'
                        }}>
                            <Skeleton.Image active={requestStatus} />
                        </div>

                    </div>
                </div>
                <Box sx={{ width: '100%' }} style={{display: requestStatus ? 'block' : 'none'}}>
                    <LinearProgress />
                </Box>
                <div style={{maxWidth: 1000, margin: 'auto', opacity: requestStatus ? '0' : '1'}}
                     className={'d-flex flex-row justify-content-center flex-wrap w-100'}>

                    <TitleView title={propertyData.title} className={'mt-5 ml-4 mr-4 w-100'} />

                    {/*---- PRICE SECTION -----*/}
                    <section className={'d-flex flex-row flex-wrap w-100 bg-light radius-25px'} style={{fontSize: 13, padding: '0 15px 0 15px'}}>
                        <div className={'d-flex flex-row align-items-center w-100-mb m-1 mr-3 ml-3'} style={{color: '#4f4f4f'}}>
                            <div className={'bold d-flex flex-row align-items-center text-uppercase'} style={{fontSize: 10, color: '#858585'}}>
                                <div>Precio</div>
                                <div style={{marginLeft: 5}}>
                                    {propertyData.mode?.name}:
                                </div>
                            </div>
                            <div className={'d-flex flex-row align-items-center'} style={{marginLeft: 5}}>
                                <span>{process.env.REACT_APP_BUSINESS_CURRENCY}$</span>
                                <div style={{marginLeft: 3}}>
                                    {
                                        parseFloat(propertyData?.price).toLocaleString()
                                    }
                                </div>
                                <Skeleton active={requestStatus} style={{display: requestStatus ? 'block' : 'none'}} />
                            </div>
                        </div>

                    </section>

                    {/*------ ADDRESS SECTION -------*/}
                    <section className={'d-flex flex-row flex-wrap border radius-10px m-2 mt-3 p-2 w-100 bg-light'} style={{fontSize: 12}}>
                        {
                            propertyData.city &&
                            <div className={'d-flex flex-column w-100-mb m-2'}
                                 style={{color: '#424242'}}>
                                <div className={'bold m-1'}>Ciudad:</div>
                                <div className={'d-flex flex-row align-items-center m-1'}>
                                    <div>{propertyData?.city?.name_es ?? '-'}</div>
                                </div>
                                <Skeleton active={requestStatus} style={{display: requestStatus ? 'block' : 'none'}} />
                            </div>
                        }
                        {
                            propertyData.country &&
                            <div className={'d-flex flex-column w-100-mb m-2'}
                                 style={{color: '#424242'}}>
                                <div className={'bold m-1'}>País:</div>
                                <div className={'d-flex flex-row align-items-center m-1'}>
                                    <div>{propertyData?.country?.name_es ?? '-'}</div>
                                </div>
                                <Skeleton active={requestStatus} style={{display: requestStatus ? 'block' : 'none'}} />
                            </div>
                        }
                        {
                            propertyData.address &&
                            <div className={'d-flex flex-column w-100-mb m-2'} style={{color: '#424242'}}>
                                <div className={'bold m-1'}>Dirección:</div>
                                <div className={'d-flex flex-row align-items-center m-1'}>
                                    <div>{propertyData?.address ?? '-'}</div>
                                </div>
                                <Skeleton active={requestStatus} style={{display: requestStatus ? 'block' : 'none'}} />
                            </div>
                        }
                    </section>

                    <div className={'w-100 mt-4 d-flex flex-row flex-wrap p-2'}>
                        {propertyData.media.length !== 0 &&
                            <div>
                                <a href={process.env.REACT_APP_REMOOX_API_ROUTE + '/properties/' + property.id + '/media/image/builder/'
                                        + propertyData?.media[propertyData.media.length - 1]?.name} target='_blank'>
                                    <img className={'radius-15px'}
                                        src={process.env.REACT_APP_REMOOX_API_ROUTE + '/properties/' + property.id + '/media/image/builder/'
                                        + propertyData?.media[propertyData.media.length - 1]?.name}
                                        alt="-" style={{width: '100%', maxWidth: 500}}/>
                                </a>
                                
                            </div>
                        }

                        {/*------ DESCRIPTION SECTION -------*/}
                        <div id='description' style={{fontSize: 12, padding: '0 15px 0 15px', whiteSpace: 'normal', maxWidth: 400}} className={'mt-3'}>
                            {ReactHtmlParser(propertyData.description)}

                            <Skeleton active={requestStatus} style={{display: requestStatus ? 'block' : 'none'}} />
                            <Skeleton active={requestStatus} style={{display: requestStatus ? 'block' : 'none'}} />
                        </div>
                    </div>

                    <div id={'field-detail-container'} className={'m-2 mt-5 w-100 pl-1 pr-1 radius-15px border'}
                         style={{backgroundColor: '#f3f3f3'}}>
                        <h3 className={'bold d-flex flex-row align-items-center mt-4 p-3 bg-white'}
                            style={{fontSize: 12, color: '#858585'}}>
                            Características y detalles
                        </h3>
                        <PropertyFieldDetails property_id={property.id} />
                    </div>
                </div>

                <div className={'mt-5 w-100 d-flex flex-row flex-wrap justify-content-center'}
                     style={{backgroundColor: 'rgb(161 206 217)', padding: '50px 15px 80px 15px'}}>
                    <div className={'mt-5 shadow-down-medium bg-light'} style={{borderRadius: 100, padding: 1}}>
                        <img src={ImageAgentWomen} alt="-" height={140} width={140} className={''}
                            />
                    </div>
                    <h3 className={'mt-2 p-3 text-center text-uppercase w-100'}
                        style={{fontSize: 14, color: '#676767', fontWeight: 'bold'}}>
                        Canales de contacto
                    </h3>
                    <div style={{fontSize: 14,color: '#565656', padding: '0 15px 15px 15px'}} >
                        Recibe más información contactando con el vendedor:
                    </div>
                    <ContactLinks property_id={property.id} />
                    <div className="mt-2 p-2" style={{fontSize: 12}}>
                        <p>
                            Leer las <a href={process.env.REACT_APP_BUSINESS_NORMS_POLICIES_LINK}>Normas y Políticas de {process.env.REACT_APP_BUSINESS_NAME}.</a>
                        </p>
                    </div>
                </div>

                { 
                    propertyData.city &&
                    <div style={{maxWidth: 1000, margin: 'auto',}}
                        className={'d-flex flex-row justify-content-center flex-wrap w-100 mt-5'}>
                        <h3 className={'text-left w-100'}
                            style={{fontSize: 16, color: '#676767', marginLeft: 25}}>
                            Avisos similares en {propertyData?.city?.name_es ?? '-'}
                        </h3>
                        <RelatedAd property_id={propertyId}
                            className={'d-flex flex-row flex-wrap w-100 mt-2 align-content-between'} />
                    </div>
                }    

                <div style={{maxWidth: 1000, margin: 'auto',}}
                     className={'d-flex flex-row justify-content-center flex-wrap w-100 mt-5'}>
                    <h3 className={'text-left w-100'}
                        style={{fontSize: 16, color: '#676767', marginLeft: 25}}>
                        Avisos similares en {process.env.REACT_APP_BUSINESS_COUNTRY}
                    </h3>
                    <RelatedAdWithoutCategory property_id={propertyId}
                       className={'d-flex flex-row flex-wrap w-100 mt-2 align-content-between'} />
                </div>

            </div>

            <Footer />
        </div>

    )

}

export default PropertyDetails;