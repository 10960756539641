import {useEffect, useState} from "react";
import {
    BrowserRouter, Routes, Route, Redirect, Outlet,
} from "react-router-dom";

// Google Analitycs 
// V3 Deprecated
//import ReactGA from 'react-ga';
import ReactGA from "react-ga4";

import {AuthRoutes} from './middlewares/AuthRoutes'
import {getCookie, setCookie, removeCookie} from './utils/cookies_functions'
import './App.css';
import NotFound from './pages/NotFound'
import Home from './pages/Home'
import Menu from './pages/Menu'
import PropertyDetails from './pages/property/PropertyDetails'

function App() {

    //const TRACKING_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID; // TRANCKING OR MEASUREMENT ID
    //ReactGA.initialize(TRACKING_ID);

    // Google Analytics V4
    useEffect(() => {
        //ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: document.title });
    },[]);

    /* // Analitycs V3 Deprecated
    ReactGA.initialize(TRACKING_ID);

    useEffect(() => {
        //ReactGA.pageview(window.location.pathname + window.location.search);
    },[]);
    */

    // const AuthGuard = () => {
    //     const authRoutes = new AuthRoutes();
    //
    //     authRoutes.userAuth().then((res)=>{
    //         if(res.status === 403 || res.data.auth === false || getCookie('api_token') === null){
    //             return window.location.href = process.env.REACT_APP_REMOOX_CORE_ROOT + '/login';
    //         }
    //     }).catch(err => {
    //         if(err.request.status === 403){
    //             window.location.href = process.env.REACT_APP_REMOOX_CORE_ROOT + '/login';
    //         }
    //     });
    // }

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home/>} exact />
                <Route path="/menu" element={<Menu/>}/>
                <Route path="/property/details/:id" element={<PropertyDetails/>} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
