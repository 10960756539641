import React, {useEffect, useState} from 'react';
import WithoutImage from '../../../img/utils/without_image.svg'
import {Link} from "react-router-dom";
import LocationOnIcon from '@mui/icons-material/LocationOn';

export default function ItemProduct (props){

    const apiRoute = process.env.REACT_APP_REMOOX_API_ROUTE;

    return (
        <a href={'/property/details/' + props.property.id} target={props.openInNewTab ? '_blank' : '_self'}
              className={'card d-flex flex-column p-1 m-2 position-relative w-100-mb'}
              style={{width: 300, height: 250, minWidth: 290, cursor: 'pointer'}}>

            <div className='position-absolute p-2 bg-main text-white d-flex align-items-center'
                 style={{top: 15, right: 4, fontSize: 11, borderRadius: '5px 0 0 5px', opacity: '0.8'}}>
                <div className='m-1'>{process.env.REACT_APP_BUSINESS_CURRENCY}$</div>
                <div>{parseFloat(props.property.price).toLocaleString()}</div>
            </div>

            <div className={'w-100 d-flex justify-content-center'} style={{backgroundColor: '#858585', minHeight: 187, maxHeight: 187}}>
                {
                    props.property.media ?
                        <img src={apiRoute + '/properties/'+ props.property.id +'/media/image/builder/' + props.property?.media[0]?.name}
                             alt="-" style={{maxHeight: 200, maxWidth: 280}} />
                        : <img src={WithoutImage}
                               alt="-" style={{maxHeight: 187}} />
                }
            </div>

            <div className='position-absolute p-2 text-white'
                 style={{bottom: 60, left: 5, fontSize: 11, borderRadius: '0 5px 5px 0', opacity: '0.8',
                     backgroundColor: '#6b6b6b'}}>
                <div>{props.property?.mode?.name}</div>
            </div>

            <div className='text-center w-100 p-2 position-absolute'
                 style={{fontSize: 12, textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', bottom: 22}}>
                {props.property?.title}
            </div>
            {
                <div className='text-right w-100 position-absolute'
                     style={{bottom: 5, right: 5, fontSize: 10, color: '#919191', display: props.property?.city || props.property?.country ? 'block' : 'none'}}>
                    <LocationOnIcon style={{fontSize: 13, color: 'rgba(37,185,217,0.64)'}} />
                    {props.property?.city?.name_es ?? props.property?.country?.name_es}
                </div>
            }
        </a>

    )
}

// export default class ItemProduct extends React.Component {
//
//     apiRoute = process.env.REACT_APP_REMOOX_API_ROUTE;
//
//     constructor(props) {
//         super(props);
//         // console.log('this.props.property', this.props.property)
//     }
//
//     render() {
//         return (
//             <Link to={'/property/details/' + this.props.property.id}
//                   className={'card d-flex flex-column p-1 m-2 position-relative w-100-mb'}
//                  style={{width: 300, height: 250, minWidth: 290, cursor: 'pointer'}}>
//
//                 <div className='position-absolute p-2 bg-main text-white d-flex align-items-center'
//                      style={{top: 15, right: 4, fontSize: 11, borderRadius: '5px 0 0 5px', opacity: '0.8'}}>
//                     <div className='m-1'>{process.env.REACT_APP_BUSINESS_CURRENCY}$</div>
//                     <div>{parseFloat(this.props.property.price).toLocaleString()}</div>
//                 </div>
//
//                 <div className={'w-100 d-flex justify-content-center'} style={{backgroundColor: '#858585', minHeight: 187, maxHeight: 187}}>
//                     {
//                         this.props.property.media ?
//                         <img src={this.apiRoute + '/properties/'+ this.props.property.id +'/media/image/builder/' + this.props.property?.media[0]?.name}
//                              alt="-" style={{maxHeight: 200, maxWidth: 280}} />
//                             : <img src={WithoutImage}
//                                    alt="-" style={{maxHeight: 187}} />
//                     }
//                 </div>
//
//                 <div className='position-absolute p-2 text-white'
//                      style={{bottom: 60, left: 5, fontSize: 11, borderRadius: '0 5px 5px 0', opacity: '0.8',
//                         backgroundColor: '#6b6b6b'}}>
//                     <div>{this.props.property?.mode?.name}</div>
//                 </div>
//
//                 <div className='text-center w-100 p-2 position-absolute'
//                      style={{fontSize: 12, textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', bottom: 22}}>
//                     {this.props.property?.title}
//                 </div>
//                 {
//                     <div className='text-right w-100 position-absolute'
//                          style={{bottom: 5, right: 5, fontSize: 10, color: '#919191', display: this.props.property?.city || this.props.property?.country ? 'block' : 'none'}}>
//                         <LocationOnIcon style={{fontSize: 13, color: 'rgba(37,185,217,0.64)'}} />
//                         {this.props.property?.city?.name_es ?? this.props.property?.country?.name_es}
//                     </div>
//                 }
//             </Link>
//
//         )
//     }
//
// }