import React from "react";
import axios from 'axios';
import {getCookie} from "../utils/cookies_functions";

export class AuthRoutes extends React.Component{
    #auth; // PRIVATE AUTH

    constructor(props) {
        super(props);
        this.state = {
            auth: false
        }

        this.userAuth();
    }

    userAuth(){
        try{
            const api_token = getCookie('api_token');

            return axios.post(process.env.REACT_APP_REMOOX_API_MAIN + '/security/user/auth/check', {},
                {
                    headers: {
                        'apiKey': process.env.REACT_APP_REMOOX_API_KEY,
                        'api_token': api_token,
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + api_token,
                    }
                }
            );
        }catch (e){
            this.setState({auth: false})
        }
    }

    getAuth = ()=>{
        return this.state.auth;
    }

}