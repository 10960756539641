import React from "react";
// import { useNavigate, Link } from "react-router-dom";
import SearchIcon from '@mui/icons-material/Search';
import ContentPasteSearchTwoToneIcon from '@mui/icons-material/ContentPasteSearchTwoTone';
import CloseIcon from '@mui/icons-material/Close';
import Loading from './includes/Loading';
import {TextField} from "@mui/material";
import {getModesCollection} from '../services/PropertyTradingModeService';
import {getCitiesByCountryCode} from "../services/CountryAndCityService";
import {getCategories, searchPropertiesAndFilters} from "../services/PropertyService";
import {getCookie, setCookie} from "../utils/cookies_functions";
import {loading} from "../utils/app_functions";
import {withRouter} from 'react-router-dom';

export default class Filters extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            openSearch: true,
            searchForm: false,
            collectionMode: [],
            cities: [],
            categories: [],
            price_from: [
                0, 25000, 50000, 75000, 100000, 150000, 200000, 250000, 300000, 350000, 400000, 450000, 500000,
                550000, 600000, 700000, 800000, 900000, 1000000, 1250000, 1500000, 1750000, 2000000, 2500000, 3000000,
                3500000, 4000000, 4500000, 5000000
            ],
            price_limit: [
                0, 25000, 50000, 75000, 100000, 150000, 200000, 250000, 300000, 350000, 400000, 450000, 500000,
                550000, 600000, 700000, 800000, 900000, 1000000, 1250000, 1500000, 1750000, 2000000, 2500000, 3000000,
                3500000, 4000000, 4500000, 5000000
            ],
            formData: {
                search_value: null,
                cities: null,
                mode: null,
                category: null,
                price_from: null,
                price_limit: null,
            }
        }

        this.showSearchForm = this.showSearchForm.bind(this);
        this.hideSearchForm = this.hideSearchForm.bind(this);
        this.submit = this.submit.bind(this);
    }

    componentDidMount() {

        this.getCollectionModes('id', 'asc', 10);
        this.getCities(process.env.REACT_APP_BUSINESS_COUNTRY_CODE);
        this.getCategories();

        if(getCookie('search-config')){
            const searchConfig = JSON.parse(getCookie('search-config'));

            this.setState({
                formData: {
                    search_value: searchConfig.search_value ?? null,
                    price_from: searchConfig.price_from ?? null,
                    price_limit: searchConfig.price_limit ?? null,
                }
            }, ()=>{
                document.getElementById('search-value').value = searchConfig.search_value;
                document.getElementById('price-from').value = searchConfig.price_from;
                document.getElementById('price-limit').value = searchConfig.price_limit;
            })
        }
        
    }

    getCollectionModes = async (order_by_column, order_by_orientation, limit)=>{
        return getModesCollection(order_by_column, order_by_orientation, limit)
            .then(res => {
                this.setState({collectionMode: res.data.modes})

                if(getCookie('search-config')){
                    const searchConfig = JSON.parse(getCookie('search-config'));
        
                    this.setState({
                        formData: {
                            mode: searchConfig.mode ?? null,
                        }
                    }, ()=>{
                        document.getElementById('mode').value = searchConfig.mode;
                    })
                }
            }).catch(err => {
            console.log('err', err)
        })
    }

    getCities = async (code)=>{
        return getCitiesByCountryCode(code)
            .then(res => {
                this.setState({cities: res.data.cities})

                if(getCookie('search-config')){
                    const searchConfig = JSON.parse(getCookie('search-config'));
        
                    this.setState({
                        formData: {
                            cities: searchConfig.cities ?? null,
                        }
                    }, ()=>{
                        document.getElementById('cities').value = searchConfig.cities;
                    })
                }
            }).catch(err => {
                console.log('err', err)
            })
    }

    getCategories = async ()=>{
        return getCategories()
            .then(res => {
                this.setState({categories: res.data.categories})

                if(getCookie('search-config')){
                    const searchConfig = JSON.parse(getCookie('search-config'));
        
                    this.setState({
                        formData: {
                            category: searchConfig.category ?? null,
                        }
                    }, ()=>{
                        document.getElementById('category').value = searchConfig.category;
                    })
                }
            }).catch(err => {
                console.log('err', err)
            })
    }

    showSearchForm(){
        this.setState({
            searchForm: true,
            openSearch: false,
        });
    }

    hideSearchForm(){
        this.setState({
            openSearch: true,
            searchForm: false,
        });
    }

    submit(e){
        e.preventDefault();
        loading(true);

        const data = {
            search_value: document.getElementById('search-value').value,
            cities: document.getElementById('cities').value,
            mode: document.getElementById('mode').value,
            category: document.getElementById('category').value,
            price_from: document.getElementById('price-from').value,
            price_limit: document.getElementById('price-limit').value,
        }

        this.setState({
            formData: data
        })
        
        setCookie('search-config', JSON.stringify(data));
        setCookie('search-active', true);

        if(window.location.pathname !== '/'){
            window.location.href = '/';
        }else{
            window.location.reload();
        }

        loading(false);

    }

    render() {
        return (
            <div style={{zIndex: '3000'}}>
                <div style={{display: this.state.openSearch ? 'block' : 'none', position: 'fixed', top: 60, right: 5}}>
                    <button onClick={this.showSearchForm} style={{border:'1px solid #5f848c', backgroundColor: 'white', borderRadius: 3}}>
                        <SearchIcon style={{fontSize: 23, color: '#5f848c'}} />
                    </button>
                </div>
                <div style={{display: this.state.searchForm ? 'block' : 'none', position: 'fixed', top: 55, right: 5, zIndex: '4500'}}>
                    <button onClick={this.hideSearchForm} 
                        style={{border:'1px solid #5f848c', backgroundColor: 'white', borderRadius: 3}}>
                        <CloseIcon style={{fontSize: 35, color: '#5f848c'}} />
                    </button>
                </div>
                <div className="card p-2"
                    style={{display: this.state.searchForm ? 'block' : 'none', position: 'fixed', top: 60, left: 10,
                        maxWidth: 900, minWidth: 250, paddingRight: 20, zIndex: '4000'}}>

                    <form onSubmit={this.submit} className="d-flex flex-column mt-1 ml-2 mr-2 w-100" style={{paddingBottom: 40}}>
                        <TextField id="search-value" label="Buscador de avisos..." variant="standard"
                            style={{width: '95%'}}
                            onKeyUp={this.search} value={this.state.formData.search_value}
                            className="form-control text-center" placeholder="Describe el aviso que deseas encontrar..."/>

                        <div className="d-flex flex-row flex-wrap mt-3">
                            <div className="m-2 w-100-mb">
                                <label htmlFor={'cities'} style={{fontSize: 12, fontWeight: 400}}
                                    className={'color-secondary m-1 w-100'}>
                                    Ciudades:
                                </label>
                                {
                                    this.state.cities &&
                                    <select id={'cities'} className={'m-1 mt-2 card pt-1 pb-1 w-100-mb'} style={{fontSize: 12}} placeholder="Seleccionar">
                                        <option value={'all'} selected={true}>Todas</option>
                                        {
                                            this.state.cities.map((city)=>{
                                                return <option key={city.id} value={city.id} 
                                                    selected={this.state.formData.cities && this.state.formData.cities === city.id ? true : false} >
                                                    {city.name_es}
                                                </option>
                                            })
                                        }
                                    </select>
                                }     
                            </div>
                            <div className="m-2 w-100-mb">
                                <label htmlFor={'mode'} style={{fontSize: 12, fontWeight: 400}}
                                    className={'color-secondary m-1 w-100'}>
                                    Modo de negociación:
                                </label>
                                {
                                    this.state.collectionMode &&
                                    <select id={'mode'} className={'m-1 mt-2 card pt-1 pb-1 w-100-mb'} style={{fontSize: 12}} >
                                        <option value={'all'} selected={true}>Todas</option>
                                        {
                                            this.state.collectionMode.map((mode)=>{
                                                return <option key={mode.id} value={mode.id} selected={this.state.formData.mode && this.state.formData.mode === mode.id ? true : false}>
                                                    {mode.name}
                                                </option>
                                            })
                                        }
                                    </select>
                                }
                            </div>
                            <div className="m-2 w-100-mb">
                                <label htmlFor={'category'} style={{fontSize: 12, fontWeight: 400}}
                                       className={'color-secondary m-1 w-100'}>
                                    Categorías:
                                </label>
                                {
                                    this.state.categories &&
                                    <select id={'category'} className={'m-1 mt-2 card pt-1 pb-1 w-100-mb'} style={{fontSize: 12}}>
                                        <option value={'all'} selected>Todas</option>
                                        {
                                            this.state.categories.map((category) => {
                                                return <option key={category.id} value={category.id}
                                                    selected={this.state.formData.category && this.state.formData.category === category.id ? true : false}>
                                                    {category.name}
                                                </option>
                                            })
                                        }
                                    </select>
                                }
                            </div>
                            <div className="m-2 w-100-mb">
                                <label htmlFor={'price-from'} style={{fontSize: 12, fontWeight: 400}}
                                       className={'color-secondary m-1 w-100'}>
                                    Rango de precio:
                                </label>
                                <div className={'d-flex flex-row align-items-center'}>
                                    <select id={'price-from'} className={'m-1 mt-2 card pt-1 pb-1'} style={{fontSize: 12}}>
                                        <option value={'all'} selected>Precio desde</option>
                                        {
                                            this.state.price_from.map((index) => {
                                                return <option key={index} value={index}
                                                    selected={this.state.formData.price_from && this.state.formData.price_from === index ? true : false}>
                                                    {process.env.REACT_APP_BUSINESS_CURRENCY} ${parseFloat(index).toLocaleString()}
                                                </option>
                                            })
                                        }
                                    </select>
                                    <div className={'m-1'} style={{fontSize: 12}}>hasta</div>
                                    <select id={'price-limit'} className={'m-1 mt-2 card pt-1 pb-1'} style={{fontSize: 12}}>
                                        <option value={'all'} selected>Precio límite</option>
                                        {
                                            this.state.price_limit.map((index) => {
                                                return <option key={index} value={index}
                                                    selected={this.state.formData.price_limit && this.state.formData.price_limit === index ? true : false}>
                                                    {process.env.REACT_APP_BUSINESS_CURRENCY} ${parseFloat(index).toLocaleString()}
                                                </option>
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className={'d-flex flex-row flex-wrap justify-content-center mt-3'}>
                            <button type={'submit'} className={'btn bg-main text-white m-1 w-100'} style={{maxWidth: 400}}>
                                <ContentPasteSearchTwoToneIcon fontSize={'medium'} style={{color: 'white'}} />
                                <span className={'m-1'}>Buscar</span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}