import React from 'react';
import '../../css_pages/includes.css'


export default class TitleView extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <h1 className={'title-view ' + this.props.className}>
                {this.props.title}
            </h1>
        )
    }
}