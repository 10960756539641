import React from 'react';
import {getPropertiesCollection} from '../../services/PropertyService'
import Item from './items/ItemProduct'
import {loading} from '../../utils/app_functions'
import {Skeleton} from "antd";
import {Box, LinearProgress} from "@mui/material";

export default class CollectionRow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            collection: [],
            requestStatus: true,
            // refRequestStatus: null,
        }
    }

    componentDidMount() {
        this.getCollection(1, 'desc', 27);
    }

    getCollection = async (start_id, order_by, limit)=>{
        loading(true);
        this.setState({requestStatus: true});
        return getPropertiesCollection(start_id, order_by, limit)
            .then(res => {
                this.setState({collection: res.data?.properties})
                this.setState({requestStatus: false});
                return loading(false);
            }).catch(err => {
            console.log('err', err)
                this.setState({requestStatus: false});
                return loading(false);
        })
    }

    render() {
        return (
            <section>

                <div className={'bg-main pt-5'}>
                    <h2 style={{fontSize: 14, fontWeight: 400, margin: '20px 0 0 0'}}
                        className={'text-white p-3 w-100 text-center text-uppercase'}>
                        Publicaciones recientes
                    </h2>
                    {
                        this.state.collection.length &&
                        <div className={'d-flex flex-row align-items-center w-100 overflow-x-auto'}
                             style={{padding: '0 0 70px 0', opacity: this.state.requestStatus ? '0' : '1'}}>
                            {
                                this.state.collection.map((property)=>{
                                    return <Item property={property} key={property.id} openInNewTab={true} />
                                })
                            }
                        </div>
                    }

                    <Box sx={{ width: '100%' }}
                         style={{display: this.state.requestStatus ? 'block' : 'none'}}>
                        <LinearProgress />
                    </Box>
                </div>
            </section>

        )
    }

}