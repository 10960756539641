import React from 'react';
import {Link} from "react-router-dom";

export default class Navigation extends React.Component {
    constructor(props) {
        super(props);
    }

    render(){
        return (
            <div style={{top: 58, left: 5, fontSize: 10, fontWeight: '500'}}
                 className="d-flex flex-row align-items-center flex-wrap ml-2 position-fixed">
                {
                    this.props.config.map((data, index)=>{
                        return <div key={index}>
                            {
                                data.url !== null
                                    ? <Link to={data.url} className="color-primary" style={{margin: 3}}>{data.label}</Link>
                                    : <span style={{margin: 3, color: '#676767'}}>{data.label}</span>
                            }
                            {
                                data.url !== null ? <span style={{margin: 3}}> > </span> : null
                            }
                        </div>

                    })
                }
            </div>
        )
    }
}