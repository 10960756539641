import React from 'react';
import {loading} from "../../utils/app_functions";
import {getContactsLinks} from "../../services/PropertyContactLinksService";

export default class ContactLinks extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            contactLinks: []
        }
    }

    componentDidMount() {
        this.getContactLinks(this.props.property_id);
    }

    async getContactLinks(property_id){
        if(property_id){
            loading(true);
            return getContactsLinks(property_id)
                .then(res => {
                    this.setState({contactLinks: res.data.contact_links})
                    loading(false);
                }).catch(err => {
                    loading(false);
                })
        }

    }

    render() {
        return (
            <div className={'w-100 d-flex flex-row flex-wrap justify-content-center'}>
                {
                    this.state.contactLinks && this.state.contactLinks.map((contact)=>{
                        return (
                            <a href={contact.url} target={contact.target ?? '_blank'} key={contact.id}
                               className={'d-flex flex-row align-items-center w-100-mb m-3 p-1 radius-25px bg-white'}
                               style={{color: '#565656', fontSize: 14}}>

                                <div className={'bold m-1 text-center w-100'} style={{whiteSpace: 'nowrap'}}>
                                    {contact.label ?? '-'}
                                </div>
                            </a>
                        )
                    })
                }
            </div>
        )
    }
}