import axios from 'axios';

const apiRoute = process.env.REACT_APP_REMOOX_API_ROUTE;

// This keeps a record of the user's views for each property
// Params in data: uri, model_id
const setRouteInfo = async (uri, model_id)=>{
    return axios.post(apiRoute + '/properties/route/user/view/count',
        {
            uri: uri,
            model_id: model_id
        },
        {
            headers: {
                'apiKey': process.env.REACT_APP_REMOOX_API_KEY,
                'Content-Type': 'application/json',
            }
        }
    );
}

const getRouteViewsCount = async (project_id)=>{
    return axios.get(apiRoute + '/properties/' + project_id + '/route/user/view/count',
        {
            headers: {
                'apiKey': process.env.REACT_APP_REMOOX_API_KEY,
                'Content-Type': 'application/json',
            }
        }
    );
}

export {
    setRouteInfo, getRouteViewsCount,
}