import axios from 'axios';

const apiRoute = process.env.REACT_APP_REMOOX_API_ROUTE;

const getModesCollection = async (order_by_column, order_by_orientation, limit)=>{
    return await axios.get(apiRoute + '/negotiations/modes/list?order_by_column='+order_by_column+'&order_by_orientation='+order_by_orientation+'&limit='+limit,
        {
            headers: {
                'apiKey': process.env.REACT_APP_REMOOX_API_KEY,
                'Content-Type': 'application/json',
            }
        }
    );
}

export {
    getModesCollection,
}