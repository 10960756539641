import React from "react";
import { Link } from "react-router-dom";
import LogoBusiness from "../img/business/business.svg"
import GridViewTwoToneIcon from '@mui/icons-material/GridViewTwoTone';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import Loading from './includes/Loading';
import Search from './Search';
import SearchIcon from "@mui/icons-material/Search";
import {getCookie} from "../utils/cookies_functions";
import AddIcon from "../img/icons/add.svg";

export default class Nav extends React.Component{

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="d-flex flex-column bg-white z-2 position-fixed top-0 w-100">
                <nav className="d-flex flex-row justify-content-between align-items-center bg-white z-2 w-100 shadow-down-medium">
                    <Link to={'/'} className="p-2 ml-2 d-flex flex-row align-items-center" title={"Página inicial del módulo de contabilidad"}>
                        <div>
                            <img id={'logo'} src={LogoBusiness} alt={process.env.REACT_APP_BUSINESS_NAME} width={35} height={35} className="mb-1" />
                            <Loading />
                        </div>
                        <div className="ml-3 mt-1">
                            <div className="color-secondary text-uppercase" style={{fontSize: 11}}>{process.env.REACT_APP_BUSINESS_NAME}</div>
                            <div className="color-secondary text-dark" style={{fontSize: 9}}>{process.env.REACT_APP_BUSINESS_COUNTRY}</div>
                        </div>
                    </Link>

                    <div className="p-2 d-flex flex-row align-items-center">
                        <Link to="/" className="mr-3" title={"Página principal"}>
                            <HomeIcon className="color-secondary" />
                        </Link>

                        <Link to="/menu" className="mr-2" title={"Menú de opciones con buscador de módulos y enlaces"}>
                            <MenuIcon className="color-secondary" />
                        </Link>
                    </div>
                </nav>
                <Search />
                <div style={{position: 'fixed', top: 110, right: 6, zIndex: '2000'}}>
                    <a href={ !getCookie('api_token') ?
                        process.env.REACT_APP_REMOOX_CORE_ROOT + '/api/client/login?route_after=' + process.env.REACT_APP_URL
                        : process.env.REACT_APP_REMOOX_CORE_ROOT + '/properties'
                    }
                       style={{backgroundColor: 'white', borderRadius: 3, border:'1px solid #5f848c', padding: '4px 9px 6px 9px',}} >
                        <img src={AddIcon} height={16} width={16} alt="|" className={'bg-white'}/>
                    </a>
                </div>
            </div>
        );
    }
}