import React from 'react'
import {getCookie, removeCookie} from "../utils/cookies_functions";
import '../css_pages/menu.css'
import Nav from "../components/Nav"
import {TextField} from "@mui/material";
import { Link } from "react-router-dom";
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';

import IntegrationsIcon from '../img/icons/integrations.svg'
import CashRegisterIcon from '../img/icons/cash_register.svg'
import BusinessExpensesIcon from '../img/icons/business_expenses.svg'
import AddIcon from '../img/icons/add.svg'
import LogoutIcon from '../img/icons/logout.svg'
import LoginIcon from '../img/icons/login.svg'
import ReactFlagsSelect from "react-flags-select";

const icon_size = 50;

export default class Menu extends React.Component {

    countries = [
        // "CO", "DO", "EC", "HT", "MX", "PR", "US",
        "DO"
    ];

    constructor(props){
        super(props);
        this.state = {
            userAuth: false,
            selectCode: process.env.REACT_APP_BUSINESS_COUNTRY_CODE,
        }
    }

    componentDidMount(){
        this.setState({userAuth: getCookie('api_token') ? true : false});
    }

    search(e){
        let names_tool = document.querySelectorAll('.name_tool');

        names_tool.forEach((el)=>{
            let el_text = el.innerHTML.toLowerCase();

            if(el_text.search(e.target.value.toLowerCase()) === -1){
                el.closest('.card').classList.remove('d-flex');
                el.closest('.card').classList.add('none');
                el.closest('.card').style.display = 'none';
            }else{
                el.closest('.card').classList.add('d-flex');
                el.closest('.card').classList.remove('none');
                el.closest('.card').style.display = 'flex';
            }
        });

    }

    onSelectCode = (code)=>{
        this.setState({selectCode: code});

        window.location.href = `https://${code.toLowerCase() + '.' + process.env.REACT_APP_REMOOX_DOMAIN}`;
    }

    render() {

        return (
            <div>
                <Nav />

                <div className="d-flex flex-row flex-wrap w-100 justify-content-center p-1 bg-light" style={{marginTop: 60}}>

                    <div className="card w-100-mb p-2 m-1 d-flex flex-column align-items-center justify-content-center"
                         style={{minWidth: '12%'}}>
                        <ReactFlagsSelect selected={this.state.selectCode} onSelect={this.onSelectCode} style={{backgroundColor: 'white'}}
                            countries={this.countries} showSelectedLabel={true} showOptionLabel={true} searchPlaceholder={true} 
                        />
                    </div>

                    {
                        !this.state.userAuth && 
                        <div className="card w-100-mb p-2 m-1 d-flex flex-column align-items-center justify-content-center"
                            style={{minWidth: '12%'}}>
                            <a href={process.env.REACT_APP_REMOOX_CORE_ROOT + '/api/client/login?route_after=' + process.env.REACT_APP_URL}
                                className="d-flex flex-row align-items-center">
                                <div className="icon-circle-menu-link">
                                    <div className="icon-circle-menu" style={{width: '30px', height: '30px'}}>
                                        <img src={LoginIcon} height={20} width={20} alt="|"/>
                                    </div>
                                </div>
                                <div className="name_tool ml-2" style={{fontSize: 15}}>Iniciar sesión</div>
                            </a>
                        </div>
                    }

                    {
                        this.state.userAuth && 
                        <div className='d-flex flex-row align-items-center flex-wrap'>
                            
                            <div className="card w-100-mb p-2 m-1 d-flex flex-column align-items-center justify-content-center"
                                style={{minWidth: '12%'}}>
                                <a href={process.env.REACT_APP_REMOOX_CORE_ROOT + '/properties'}
                                    className="d-flex flex-row align-items-center">
                                    <div className="icon-circle-menu-link">
                                        <div className="icon-circle-menu" style={{width: '13px', height: '13px'}}>
                                            {/*<IntegrationInstructionsRoundedIcon style={{fontSize: 35}} />*/}
                                            <img src={AddIcon} height={12} width={12} alt="|"/>
                                        </div>
                                    </div>
                                    <div className="name_tool ml-2" style={{fontSize: 15}}>Publicar anuncio</div>
                                </a>
                            </div>

                            <div className="card w-100-mb p-2 m-1 d-flex flex-column align-items-center justify-content-center"
                                style={{minWidth: '12%'}}>
                                <a href={process.env.REACT_APP_REMOOX_CORE_ROOT + '/logout?route_after=' + process.env.REACT_APP_URL}
                                    className="d-flex flex-row align-items-center">
                                    <div className="icon-circle-menu-link">
                                        <div className="icon-circle-menu" style={{width: '18px', height: '18px'}}>
                                            {/*<IntegrationInstructionsRoundedIcon style={{fontSize: 35}} />*/}
                                            <img src={LogoutIcon} height={15} width={15} alt="|"/>
                                        </div>
                                    </div>
                                    <div className="name_tool ml-2" style={{fontSize: 15}}>Cerrar sesión</div>
                                </a>
                            </div>
                        </div>
                        
                    }
                    
                </div>
            </div>
        )
    }
}