import React from 'react'

export default class Footer extends React.Component {

    getThisYear = ()=>{
        const date = new Date();

        return date.getFullYear();
    }

    render() {
        return (
            <footer id={'footer'} className={'d-flex flex-row align-items-center flex-wrap justify-content-between w-100 p-3'}
                style={{backgroundColor: '#018ba8', color: '#FFF', fontSize: 12}}>
                <section className={'m-2'}>
                    Copyright ©
                    {
                        ' ' + process.env.REACT_APP_BUSINESS_NAME + ' ' +
                        this.getThisYear() + ' '
                    }
                </section>
                <section className={'m-2'}>
                    Technology by
                    <a href={'https://remoox.com'} target={'_blank'}
                       style={{color: 'white'}}>
                        <button className={'btn btn-primary bg-main ml-2'} style={{border: 'none'}}>
                            REMOOX
                        </button>
                    </a>
                </section>
            </footer>
        )
    }
}