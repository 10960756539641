import React from 'react';
import Nav from "../components/Nav";

export default class NotFound extends React.Component {
    render() {
        return (
            <div>
                <Nav />
                <div className="d-flex flex-row justify-content-center">
                    <h1>Página no encontrada (404)</h1>
                    <p className="text-center">
                        Este vista no existe o ha sido actualizada por otra.
                    </p>
                </div>

            </div>
        )
    }
}