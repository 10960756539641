import React from 'react';
import {getModesCollection} from '../../services/PropertyTradingModeService'
import {TextField} from "@mui/material";
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';

export default class PropertyTradingModeRow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            collection: []
        }
    }

    componentDidMount() {
        this.getCollection('id', 'asc', 10);
    }

    getCollection = async (order_by_column, order_by_orientation, limit)=>{
        return getModesCollection(order_by_column, order_by_orientation, limit)
            .then(res => {
                this.setState({collection: res.data.modes})
            }).catch(err => {
            console.log('err', err)
        })
    }

    render() {
        return (
            <section>

                <div className={'bg-white pt-5 pb-5 w-100 d-flex flex-column justify-content-center align-items-center'}>

                    <div className="d-flex flex-row justify-content-center align-items-center mt-3" style={{marginTop: 60}}>
                        <TextField id="standard-basic" label="Buscador..." variant="standard" style={{width: '50%'}}
                            onKeyUp={this.search}
                            className="form-control text-center" placeholder="Describe el contenido que deseas encontrar..."/>
                        <div className=""><SearchRoundedIcon style={{fontSize: 30, color: 'gray'}} /></div>
                    </div>
                    <h2 style={{fontSize: 16, fontWeight: 400, margin: '20px 0 0 0'}}
                        className={'color-secondary p-3 w-100 text-center'}>
                        Elije un modo de negociación:
                    </h2>
                    {
                        this.state.collection !== [] &&
                        <select className={'mt-3 card p-2'}>
                            {
                                this.state.collection.map((mode)=>{
                                    return <option key={mode.id} value={mode.id}>{mode.name}</option>
                                })
                            }
                        </select>
                    }
                </div>
            </section>

        )
    }

}